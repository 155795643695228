@import "../../../styles/variables.scss";

.toggler {
    border: none;
    background: none;
    cursor: pointer;

    .moon {
        color: $light-background-colour;
    }
    .sun {
        color: $dark-background-colour;
    }
}
