@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.blog-image {
    display: block;
    margin: 0 auto;
    max-width: $blog-contents-max-width;

    & > img {
        &.shadow {
            @include elevation-shadow-light;
        }
    }

    .blog-figure {
        margin: $spacing-sm auto $spacing-xs auto;

        img {
            &.shadow {
                @include elevation-shadow-light;
            }
        }
    }
    .blog-caption {
        text-align: center;
        margin-bottom: $spacing-md;
        color: $cool-grey-700;
    }

    .img-container {
        position: relative;
    }
}

.zoom-overlay {
    height: 100vh;
    width: 100vw;
    position: fixed;
    background: rgba(0, 0, 0, 0.65);
    top: 0;
    left: 0;
    z-index: 10;
}

.zoom-img {
    cursor: zoom-out;
    max-width: 90vw;
    width: auto;
    max-height: 90vh;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 10;
}
