// This file contains all the styles for blog components.
//
// Note: why aren't we using SCSS modules for blog components? Unfortunately,
//       there is no SCSS module support for `mdx-bundler`. Using CSS-in-JS
//       solutions is problematic as well. Eg. `styled-components` will cause
//       a nasty warning around hook violations because the styled components
//       are 'created' in `getMDXComponent`.
@import "./Image/Image.scss";

.small-divider {
    max-width: 200px;
    width: 100%;
    height: 0px;
    border-bottom: 1px solid black;
    background: black;
    margin: 32px auto;
}
