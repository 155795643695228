@mixin elevation-shadow {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 14px 0px,
        rgba(0, 0, 0, 0.25) 0px 0px 0px 1px;
}

@mixin elevation-shadow-light {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 10px 14px 0px,
        rgba(0, 0, 0, 0.1) 0px 0px 0px 1px;
}

@mixin elevation-shadow-square {
    box-shadow: rgba(0, 0, 0, 0.1) -9px 9px 0px -3px;
}

@mixin elevation-shadow-square-lg {
    box-shadow: rgba(0, 0, 0, 0.3) -12px 12px 0px -3px;
}

@mixin inset-shadow {
    box-shadow: inset 0 0 10px rgba(black, 0.35);
}

@mixin reset-ul-styles {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

@mixin reset-button-styles {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    text-align: inherit;
    background: transparent;

    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
}

@mixin clip-fade-text($maxHeight) {
    max-height: $maxHeight;
    overflow: hidden;
    mask-image: linear-gradient(
        to bottom,
        black 40%,
        transparent 95%
    ); // See: https://stackoverflow.com/questions/22808040/fading-out-text-on-overflow-with-css-if-the-text-is-bigger-than-allowed
}
