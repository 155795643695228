@import "../../../styles/variables.scss";

.searchBar {
    position: relative;
    display: inline-block;

    .searchIcon {
        position: absolute;
        top: 50%;
        left: $spacing-xs;
        transform: translateY(-50%);
    }

    label {
        // top: -16px;
        // left: 12px;
        // color: $cool-grey-500;
        // font-size: small;
        position: absolute;
        visibility: hidden;
    }

    input {
        border-radius: 100px;
        border: 1px solid rgba($color: $cool-grey-500, $alpha: 0.5);
        padding: $spacing-xs $spacing-xs $spacing-xs $spacing-lg;

        &.dark {
            background-color: $cool-grey-300;
        }

        &.light {
            background-color: rgb(246, 248, 250);
            border: 1px solid $cool-grey-800;
        }
    }
}
