@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.breadcrumbs {
    font-family: $code-font-stack;

    // Reset default <nav> and <li> styling.
    @include reset-ul-styles;

    // Keep the text and crumb separator vertically aligned.
    display: flex;
    align-items: center;
    column-gap: 6px;

    .crumb {
        @include reset-button-styles;
        display: inline-block;
        white-space: nowrap;
        max-width: 64px;
        overflow: hidden;
        text-overflow: ellipsis;

        // The last crumb in the trail should not appear clickable.
        &.clickableCrumb {
            padding: 1px 0px; // See: https://stackoverflow.com/questions/13240665/css-overflowhidden-removing-border-bottom.
            .dark,
            &.dark {
                color: $dark-text-colour;
                border-bottom: 1px dotted $dark-text-colour;
            }
            .light,
            &.light {
                color: $light-text-colour;
                border-bottom: 1px dotted $light-text-colour;
            }
            cursor: pointer;
        }

        &:not(.clickableCrumb) {
            .dark {
                color: $dark-subtitle-colour;
            }
            .light {
                color: $light-subtitle-colour;
            }
        }
    }

    .separator {
        &.dark {
            color: $dark-text-colour;
        }
        &.light {
            color: $light-text-colour;
        }
    }
}
