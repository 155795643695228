@import "../../../styles/variables.scss";

.socialLinkContainer {
    display: flex;

    .socialLink {
        padding: $spacing-xxs;
        height: 18px;
        width: 18px;
        border: 2px solid rgba($cool-grey-900, 0.2);
        background-color: rgba($primary-100, 0.3);
        border-radius: 50%;
        position: relative;

        transition: background 200ms ease;

        svg {
            position: absolute;
            color: rgba($dark-text-colour, 0.75);
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            height: 16px;
            width: 16px;
        }

        &:hover {
            background: rgba($dark-text-colour, 0.2);
        }
    }

    &.anchoredToLeft {
        flex-direction: column;
        align-items: flex-start;
        row-gap: $spacing-sm;

        top: $central-container-top-offset;
        left: $spacing-xs;
        transform: translateY(-50%);
    }

    &.anchoredToBottom {
        flex-direction: row;
        column-gap: $spacing-xxs;
        align-items: flex-start;

        left: 50%;
        transform: translateX(-50%);
        bottom: $spacing-sm;
    }
}

@media screen and (max-width: $md) {
    .socialLinkContainer {
        .socialLink {
            height: 10px;
            width: 10px;

            svg {
                height: 12px;
                width: 12px;
            }
        }
    }
}
