@import "../../../styles/variables.scss";

.miniDivider {
    display: block;
    max-width: 100px;
    margin-top: $spacing-sm;
    margin-bottom: $spacing-sm;
    height: 0px;
    border: none;
    border-top: 0.5px solid rgba($color: $dark-subtitle-colour, $alpha: 0.6);

    &.left {
        margin-right: auto;
        margin-left: 0;
    }

    &.right {
        margin-left: auto;
        margin-right: 0;
    }
}
