@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

$edge-skew-angle: -1deg;

@mixin slantedEdge {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background: inherit;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: skewY($edge-skew-angle);
}

.blogBody {
    background: rgba($color: #000000, $alpha: 0);
    width: 100%;
    z-index: -10;

    .slantedContainer {
        position: relative;
        width: 100%;
        margin: 250px 0;
        transition: all $dark-mode-transition-duration ease;

        &.dark {
            background: $dark-background-colour;
            color: $dark-text-colour;
        }

        &.light {
            background: $light-background-colour;
            color: $light-text-colour;
        }

        // Creating a 'razor blade', or slanted edge.
        &:before {
            @include slantedEdge;
            transform-origin: top left;
            box-shadow: rgba(0, 0, 0, 0.3) 0px -20px 20px 0px;
        }
        &:after {
            @include slantedEdge;
            transform-origin: top right;
            box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 20px 0px;
        }

        // Top bar containing navigation UI and buttons.
        .topBar {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            box-sizing: border-box;

            .rightGroup {
                display: flex;
                flex-direction: row;
                column-gap: $spacing-sm;
            }
        }

        .separateSearchBar {
            display: flex;
            justify-content: center;
        }
    }
}
