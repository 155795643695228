@import "../../../styles/variables.scss";
@import "../../../styles/mixins.scss";

.unorderedList {
    position: relative;
    list-style: none;
    padding-left: 1.5em;

    li {
        margin-top: $spacing-xxs;
        &:before {
            content: "►";
            position: absolute;
            left: 0;
        }
    }

    &.reset {
        @include reset-ul-styles;

        li {
            &:before {
                content: "";
            }
        }
    }
}
