// SCSS variables for defining the colour palette for light/dark mode and
// font families.

// Montserrat.
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

// Ubuntu Mono.
@import url("https://fonts.googleapis.com/css2?family=Ubuntu+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap");

$theme-transition-duration: 0.2s;
$dark-mode-transition-duration: 0.2s;

$central-container-top-offset: 47%;

/**
 * Dark mode palette.
 */
$dark-background-colour: hsl(227, 21%, 15%);
$dark-text-colour: #eeeeee;
$dark-subtitle-colour: #c0b4ce;

/**
 * Light mode palette.
 */
// $light-background-colour: rgb(246, 248, 250);
$light-background-colour: white;
$light-text-colour: #101010;
$light-subtitle-colour: #818181;

/**
 * Main palette.
 */
$primary: hsl(241, 79%, 73%);
$primary-100: hsl(241, 79%, 10%);
$primary-200: hsl(241, 79%, 20%);
$primary-300: hsl(241, 79%, 30%);
$primary-400: hsl(241, 79%, 40%);
$primary-500: $primary;
$primary-600: hsl(241, 79%, 60%);
$primary-700: hsl(241, 79%, 70%);
$primary-800: hsl(241, 79%, 80%);
$primary-900: hsl(241, 100%, 90%);

$secondary: hsl(241, 70%, 86%);
$secondary-100: hsl(241, 100%, 10%);
$secondary-200: hsl(241, 100%, 30%);
$secondary-300: hsl(241, 100%, 50%);
$secondary-400: hsl(241, 100%, 60%);
$secondary-500: $secondary;
$secondary-600: hsl(241, 100%, 90%);

$tertiary: hsl(242, 17%, 62%);
$tertiary-100: hsl(242, 17%, 30%);
$tertiary-200: hsl(242, 17%, 40%);
$tertiary-300: hsl(242, 17%, 50%);
$tertiary-400: hsl(242, 17%, 55%);
$tertiary-500: $tertiary;
$tertiary-600: hsl(242, 17%, 70%);
$tertiary-700: hsl(242, 17%, 80%);
$tertiary-800: hsl(242, 30%, 90%);
$tertiary-900: hsl(242, 65%, 90%);

$cool-grey: hsl(242, 15%, 75%);
$cool-grey-100: hsl(242, 15%, 10%);
$cool-grey-200: hsl(242, 15%, 15%);
$cool-grey-300: hsl(242, 15%, 21%);
$cool-grey-400: hsl(242, 15%, 30%);
$cool-grey-500: hsl(242, 15%, 40%);
$cool-grey-600: hsl(242, 15%, 50%);
$cool-grey-700: hsl(242, 15%, 60%);
$cool-grey-800: hsl(242, 15%, 70%);
$cool-grey-900: hsl(242, 15%, 80%);
$cool-grey-1000: hsl(242, 15%, 90%);

/**
 * Typography.
 */

$regular-font-stack: "Montserrat", Helvetica, Trebuchet MS, Arial;
$code-font-stack: "Ubuntu Mono", Consolas, monaco, Courier New;

$font-size-xs: 8px;
$font-size-sm: 12px;
$font-size-md: 16px;
$font-size-lg: 20px;
$font-size-xl: 24px;
$font-size-xxl: 32px;
$font-size-x3l: 48px;
$font-size-x4l: 64px;

/**
 * Responsive breakpoints.
 */
$xs: 400px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;

/**
 * Spacing.
 */
$spacing-xxxs: 4px;
$spacing-xxs: 8px;
$spacing-xs: 12px;
$spacing-sm: 24px;
$spacing-md: 32px;
$spacing-lg: 48px;
$spacing-xl: 60px;
$spacing-xxl: 72px;

$line-spacing-sm: 1.25;
$line-spacing-md: 1.5;
$line-spacing-lg: 2;
$line-spacing-xl: 2.2;

/**
 * Blog variables.
 */
$blog-contents-max-width: 50rem;
